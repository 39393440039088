@import '../../styles/customMediaQueries.css';

h3,
p {
  margin: 0;
}
/* .interpreterNumberRequired {
  padding: 0 24px;
  @media (--viewportMedium) {
    padding: 0;
  }
} */

.descriptionTitle {
  /* Font */
  composes: h3 fontWeightSemiBold from global;
  color: var(--matterColorAnti);
  margin: 0 0 12px 12px;
  @media (--viewportMedium) {
    margin: 0 0 12px;
  }
}
/* .interpreters,
.notes {
  margin: 1rem 0;
} */

.interpreters,
.notes {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 36px;
  }
}
.container {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.itemContainer {
  display: grid;
  grid-template-columns: 70px 1fr;
  place-items: center flex-start;
  margin: 0.7rem 0;
  padding: 0 24px;
  @media (--viewportMedium) {
    padding: 0;
  }
}

.itemContainer svg {
  fill: #5d02c7;
}
.dataContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }} */
  & span {
    padding: 0 0.25rem;
  }
}
